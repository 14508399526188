import React from 'react'
import { Button } from '@mui/material'

const ContainedButton = (props: any) => {
	const { children, ...rest } = props
	return (
		<Button {...rest} variant={'contained'}>
			{children}
		</Button>
	)
}

export default ContainedButton
